// extracted by mini-css-extract-plugin
export var container = "RechargeTime-module--container--99501";
export var error = "RechargeTime-module--error--c4978";
export var grid = "RechargeTime-module--grid--d27a4";
export var iconArrow = "RechargeTime-module--icon-arrow--79061";
export var iconCities = "RechargeTime-module--icon-cities--bab7e";
export var iconClose = "RechargeTime-module--icon-close--2dbfb";
export var iconCloseModal = "RechargeTime-module--icon-close-modal--9a526";
export var iconCopy = "RechargeTime-module--icon-copy--07732";
export var iconEnergy = "RechargeTime-module--icon-energy--2aacb";
export var iconFacebook = "RechargeTime-module--icon-facebook--36ca4";
export var iconHamburger = "RechargeTime-module--icon-hamburger--2e8d1";
export var iconInstagram = "RechargeTime-module--icon-instagram--714c2";
export var iconLinkedin = "RechargeTime-module--icon-linkedin--f7c3c";
export var iconListItem = "RechargeTime-module--icon-list-item--65558";
export var iconLogo = "RechargeTime-module--icon-logo--c87ef";
export var iconMinus = "RechargeTime-module--icon-minus--e3387";
export var iconPin = "RechargeTime-module--icon-pin--96c39";
export var iconPlus = "RechargeTime-module--icon-plus--05549";
export var iconRecharges = "RechargeTime-module--icon-recharges--cc98e";
export var iconStations = "RechargeTime-module--icon-stations--029bd";
export var iconUsers = "RechargeTime-module--icon-users--56ad2";
export var iconWallbox = "RechargeTime-module--icon-wallbox--68db5";
export var iconWhatsapp = "RechargeTime-module--icon-whatsapp--350f0";
export var iconYoutube = "RechargeTime-module--icon-youtube--49c6f";
export var image = "RechargeTime-module--image--df5be";
export var item = "RechargeTime-module--item--919f2";
export var itemList = "RechargeTime-module--item-list--23d44";
export var itemListMobile = "RechargeTime-module--item-list-mobile--6068d";
export var itemTitle = "RechargeTime-module--item-title--81361";
export var knowMore = "RechargeTime-module--know-more--03bdc";
export var link = "RechargeTime-module--link--0ac51";
export var paddingBottom = "RechargeTime-module--padding-bottom--a4167";
export var paddingTop = "RechargeTime-module--padding-top--18c85";
export var path1 = "RechargeTime-module--path1--237d2";
export var path10 = "RechargeTime-module--path10--6ba08";
export var path11 = "RechargeTime-module--path11--250d9";
export var path12 = "RechargeTime-module--path12--039a2";
export var path13 = "RechargeTime-module--path13--864c1";
export var path14 = "RechargeTime-module--path14--44ef0";
export var path15 = "RechargeTime-module--path15--83275";
export var path2 = "RechargeTime-module--path2--da070";
export var path3 = "RechargeTime-module--path3--4ed43";
export var path4 = "RechargeTime-module--path4--22449";
export var path5 = "RechargeTime-module--path5--58175";
export var path6 = "RechargeTime-module--path6--c0a99";
export var path7 = "RechargeTime-module--path7--c3489";
export var path8 = "RechargeTime-module--path8--97722";
export var path9 = "RechargeTime-module--path9--6b79a";
export var rechargeSection = "RechargeTime-module--recharge-section--4702f";
export var resultSection = "RechargeTime-module--result-section--06967";
export var slider = "RechargeTime-module--slider--3def5";
export var subtitle = "RechargeTime-module--subtitle--9caea";
export var title = "RechargeTime-module--title--ef336";
export var virtualStore = "RechargeTime-module--virtual-store--17629";