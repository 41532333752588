import React, { useEffect, useState, useRef } from 'react'
import { Head, Grid, CalculationSteps, Search, ResultBrand, ShowVehicleMake, ResultCar, CarInformation, Button, Slider, Loading, } from '@components'
import * as st from '@assets/styl/RechargeTime.module.styl'
import useWindowSize from '@utils/useWindowSize'
import cn from 'classnames'
import { Link, navigate } from 'gatsby'
import { LazyImageFull, ImageState } from 'react-lazy-images'
import api from '@api'

interface Props {
  image: string
  id: number
  name: string
  slug: string
  type: string
}

interface StoreProps {
  id: number
  description: string
  link: string
  image: string
}

const RechargeTime = ({ location }) => {
  const url = 'https://admin.wecharge.com.br/api'
  const [query, setQuery] = useState('')
  const [marca, setMarca] = useState('')
  const [modelo, setModelo] = useState('')
  const [brands, setBrands] = useState<Props[]>([])

  const [step, setStep] = useState(1)
  const [carsResult, setCarsResult] = useState({
    brand: {
      name: '',
      slug: '',
    },
    id: 0,
    image: '',
    model: '',
    name: '',
    resume_labels: '',
    resume_values: '',
    slug: '',
  })

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')
  const { width: mobile } = useWindowSize()

  const [productList, setProductList] = useState<StoreProps[]>([])
  const productListRef = useRef([])
  const descriptionRef = useRef([])
  const [maxHeight, setMaxHeight] = useState(0)
  const [maxHeightDescription, setMaxHeightDescription] = useState(0)

  const timeToClean = () => {
    setTimeout(() => {
      setError('')
    }, 1000)
  }

  const apiRequest = async (
    url: string,
    currentState,
    slug?: boolean,
    currentResult?
  ) => {
    let data: Array<{}> = []
    setLoading(false)

    try {
      if (slug) {
        setLoading(false)
        step !== 3 ? ([{ slug }] = currentResult) : ({ slug } = currentResult)
        data = await api('get', `${url}/${slug}`)
      } else {
        data = await api('get', url)
      }

      if (data && 'result' in data) {
        setError('')
        currentState(data.result)
        setLoading(true)
      } else {
        setError('Sem resultados!')
      }
    } catch (error) {
      console.error('Erro ao fazer a requisição:', error)
      setError('Ocorreu um erro na requisição.')
    }
  }

  // useEffect(() => {
  //   let urlPage = '/tempo-de-recarga'
  //   const search = location.search

  //   if (location.pathname.endsWith('/')) {
  //     const completeURL = `${urlPage}${search}`;
  //     navigate(completeURL)
  //   }
    
  //   if (!location.pathname.endsWith('/')) {
  //     const completeURL = `${urlPage}/${search}`;
  //     navigate(completeURL)
  //   }
    
  //   // if (join.includes('/').endsWith('/?')) {
  //     // const newURL = `${pathname}${search}`;
  //     // console.log(newURL)
  //   // }
  // }, [])

  useEffect(() => {
    const params = new URLSearchParams(location.search)
    const busca = params.get('busca')   
    const marcaParams = params.get('marca') 

    //Preenche o brands com as marcas assim que entra na página;
    if (brands.length === 0 && busca === null && step === 1) {
      apiRequest('brands', setBrands)
    }

    //Preenche o brands com as marcas assim que volta para a 1 etapa;
    if (brands.length > 0 && busca === null && marca === '' && step === 1) {
      setBrands([])
      setLoading(false)
      apiRequest('brands', setBrands)
    }

    //Busca por marcas e modelos de carros;
    if (busca !== null && step === 1 && marcaParams === null) {
      fetch(`${url}/charge?name=${busca}`)
        .then((response) => response.json())
        .then((data) => {
          if (data.result.length === 0) {
            setBrands([])
            setError('Sem resultado para esta pesquisa!')
            setLoading(true)
          } else {
            setError('')
            setBrands(data.result)
            setLoading(true)
          }
        })
    }

    if (marca !== '' && modelo === '') {
      setLoading(false)
      setStep(2)
      setBrands([])
      let filtered = []

      if (step === 2 && busca !== null) {
        filtered = brands.filter(({ name }) => {
          return name?.toLowerCase().includes(busca?.toLowerCase())
        })

        if (filtered.length > 0) {
          setError('')
          setBrands([])
          setBrands(filtered)
          setLoading(true)
        } else {
          setBrands([])
          setError('Sem resultado para esta pesquisa!')
          setLoading(true)
        }
      } else {
        if (busca === null) { //Se a busca estiver vazia;
          fetch(`${url}/cars/brand/${marca.replace(/\s/g, '-')}`)
            .then((response) => response.json())
            .then((data) => {
              if (data.result?.length === 0) {
                setBrands([])
                setError('Sem resultado para esta pesquisa!')
              } else {
                if (filtered.length > 0) {
                } else {
                  setError('')
                  setBrands(data.result)
                  setLoading(true)
                }
              }
            })
        }
      }
    }

    if (modelo !== '') {
      setLoading(false)

      fetch(`${url}/cars/${modelo}`)
        .then((response) => response.json())
        .then((data) => {
          if (data.result?.length === 0) {
            setError('Sem resultado para esta pesquisa!')
            timeToClean()
          } else {
            setBrands([])
            setCarsResult(data.result)
            setLoading(true)
          }
        })
    }

    if (modelo !== '' && step === 3) apiRequest('store', setProductList)
  }, [step, query, location.search])

  useEffect(() => {
    let max = 0
    let maxDescription = 0

    productListRef.current.forEach((card) => {
      if (card && card.clientHeight > max) {
        max = card.clientHeight
      }
    })

    descriptionRef.current.forEach((description) => {
      if (description && description.clientHeight > maxDescription) {
        maxDescription = description.clientHeight
      }
    })

    setMaxHeight(max)
    setMaxHeightDescription(maxDescription)
  }, [step])

  useEffect(() => {
    const params = new URLSearchParams(location.search)

    setQuery(params.get('busca') || '')
    setMarca(params.get('marca') || '')
    setModelo(params.get('modelo') || '')
  }, [location.search])

  return (
    <>
      <Head path="/tempo-de-recarga" />
      <section className={st.rechargeSection}>
        <Grid className={st.container}>
          <div className={st.info}>
            <h1 className={st.title}>Tempo de recarga</h1>
            <p className={st.subtitle}>
              Calcule o tempo de recarga do seu veículo, basta seguir os 3
              passos, é simples e rápido!
            </p>
          </div>
          <CalculationSteps step={step} setMarca={setMarca} setStep={setStep} setQuery={setQuery} marca={marca} />
          {step === 2 && <ShowVehicleMake brand={marca} />}
          {step !== 3 && (
            <Search setError={setError} setQuery={setQuery} setLoading={setLoading} query={query} modelo={modelo} marca={marca} step={step} />
          )}
        </Grid>
      </section>
      <section className={cn(st.resultSection, {[st.paddingBottom]: step === 3, [st.paddingTop]: step === 3 && carsResult})}>
        <Grid className={st.container}>
          {brands?.length > 0 && step === 1 ? (
            <ResultBrand setModelo={setModelo} marca={marca} setMarca={setMarca} step={step} setStep={setStep} brands={brands} setLoading={setLoading} />
          ) : (
            !loading && <Loading />
          )}
          {brands?.length > 0 && step === 2 && (
            <ResultCar step={step} setStep={setStep} brands={brands} setLoading={setLoading} modelo={modelo} setModelo={setModelo} />
          )}
          {carsResult && step === 3 && (
            <CarInformation brand={marca} result={carsResult} setCarsResult={setCarsResult} setStep={setStep} setQuery={setQuery} loading={loading} error={error} />
          )}
          {error && <p className={st.error}>{error}</p>}
        </Grid>
      </section>
      {step === 3 && (
        <section className={st.virtualStore}>
          {loading && (
            <div className={st.grid}>
              <h2 className={st.title}>Compre agora em nossa loja virtual</h2>
              {mobile <= 749 && productList.length > 0 ? (
                <ul className={st.itemListMobile}>
                  <Slider isVirtualStore={true} overflow={mobile <= 450 ? true : false} finite responsiveSize={mobile <= 450 ? mobile : 1500} classes={{ root: st.slider }}>
                    {productList.map(({ image, description, id, link }) => (
                      <li className={st.item} key={id} ref={(card) => (productListRef.current[id] = card)} style={{ minHeight: `${maxHeight}px`}}>
                        <div className={st.image}>
                          <LazyImageFull src={image} alt={description}>
                            {({ imageState, ref, imageProps: { src } }) =>
                              <div ref={ref}>
                                <img src={imageState === ImageState.LoadSuccess ? src : ''} />
                              </div>
                          }</LazyImageFull>
                        </div>
                        <p className={st.itemTitle} ref={(description) => (descriptionRef.current[id] = description)} style={{ minHeight: `${maxHeightDescription}px`}}>{description}</p>
                        <Button className={st.knowMore} to={link} external>Saiba mais</Button>
                        <Link to={link} target="_blank" rel="external nofollow" className={st.link}></Link>
                      </li>
                    ))}
                  </Slider>
                </ul>
              ) : (
                productList.length === 0 && <p className={st.error}>Sem resultados!</p>
              )}

              {mobile > 749 && (
                <ul className={st.itemList}>
                  {productList.map(({ description, link, id, image }) => (
                    <li className={st.item} key={id} ref={(card) => (productListRef.current[id] = card)} style={{ minHeight: `${maxHeight}px`}}>
                      <div className={st.image}>
                       <LazyImageFull src={image} alt={description}>
                        {({ imageState, ref, imageProps: { src } }) =>
                          <div ref={ref}>
                            <img src={imageState === ImageState.LoadSuccess ? src : ''} />
                          </div>
                        }</LazyImageFull>
                      </div>
                      <p className={st.itemTitle} ref={(description) => (descriptionRef.current[id] = description)} style={{ minHeight: `${maxHeightDescription}px`}}>{description}</p>
                      <Button className={st.knowMore} to={link} external>Saiba mais</Button>
                      <Link to={link} target="_blank" rel="external nofollow" className={st.link}></Link>
                    </li>
                  ))}
                </ul>
              )}
            </div>
          )}
        </section>
      )}
    </>
  )
}

export default RechargeTime
